<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Categoria Paquete - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">

              <b-tabs content-class="mt-3">
                <b-tab title="Español" active>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Titulo:">
                        <b-form-input disabled v-model="package_category.title_es" ></b-form-input>
                        <small v-if="errors.title_es"  class="form-text text-danger" >Ingrese un titulo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Descripción:">
                        <b-form-input disabled v-model="package_category.description_es"></b-form-input>
                      </b-form-group>
                    </b-col>
                    
                  </b-row>
                </b-tab>
                <b-tab title="Ingles">
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Title:">
                        <b-form-input disabled v-model="package_category.title_en" ></b-form-input>
                        <small v-if="errors.title_en"  class="form-text text-danger" >Ingrese un titulo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Description:">
                        <b-form-input disabled v-model="package_category.description_en"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>

              <b-row>
                <b-col md="6">
                  <b-form-group label="Imagen:" >
                      <b-img  fluid :src="url_base+package_category.image"></b-img>
                  </b-form-group>
                </b-col>
                <b-col md="6"></b-col>
                <b-col md="10">
                  <b-form-group>
                    <label for="">Cambiar Imagen: <small>Tamaño recomendado (800x480)px</small></label>
                    <b-form-file
                      accept="image/png, image/jpeg"
                      disabled
                      v-model="file"
                      :state="Boolean(file)"
                      @change="onFileChange"
                      placeholder="Seleccione una imagen"
                      drop-placeholder="Arrastre la imagen aqui"
                    ></b-form-file>
                    <small v-if="errors.image"  class="form-text text-danger" >Seleccione una imagen</small>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Estado:">
                    <b-form-select disabled v-model="package_category.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/categoria-paquetes/listar' }" append >REGRESAR</b-link>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>



    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";

// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ["id_package_category"],
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'PackageCategory',
      role: 5,
      package_category: {
          id_package_category:'',
          title_es:'',
          description_es:'',
          title_en:'',
          description_en:'',
          image:'',
          image_change:'',
          state:1,
      },
      file:null,
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],

      errors: {
        title_es: false,
        description_es: false,
        title_en: false,
        description_en: false,
        image: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewPackageCategory();
  },
  methods: {
    EditPackageCategory,
    Validate,
    onFileChange,
    ViewPackageCategory,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function onFileChange(e) {
  this.package_category.image_change = e.target.files[0];
}

function ViewPackageCategory() {
  let me = this;
  let id_package_category = je.decrypt(this.id_package_category);
  let url = me.url_base + "packages-category/view/"+id_package_category;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        
        me.package_category.id_package_category = response.data.result.id_package_category;
        me.package_category.title_es = response.data.result.title_es;
        me.package_category.description_es = response.data.result.description_es;
        me.package_category.title_en = response.data.result.title_en;
        me.package_category.description_en = response.data.result.description_en;
        me.package_category.image = response.data.result.image;
        me.package_category.state = response.data.result.state;

      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}


function EditPackageCategory() {
  let me = this;
  let url = me.url_base + "packages-category/edit";
  let data = new FormData();
  data.append("id_package_category", me.package_category.id_package_category);
  data.append("title_es", me.package_category.title_es);
  data.append("description_es", me.package_category.description_es);
  data.append("title_en", me.package_category.title_en);
  data.append("description_en", me.package_category.description_en);
  data.append("image", me.package_category.image_change);
  data.append("state", me.package_category.state);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
          me.package_category.image = response.data.result.image;
          me.package_category.image_change = '';
          me.file = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.title_es = this.package_category.title_es.length == 0 ? true : false;
  this.errors.title_en = this.package_category.title_en.length == 0 ? true : false;
  this.errors.image = this.package_category.image.length == 0 ? true : false;
  this.errors.state = this.package_category.state.length == 0 ? true : false;

  if (this.errors.title_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.title_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.image) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar la categoria ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditPackageCategory();
    }
  });
}
</script>
